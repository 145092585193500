import React from 'react';

export default function FittingsComponent() {
    return (
        <div style={styles.container}>
            <p style={ styles.description }>MZ Engineering is a supplier of a wide range of forged & Butt Weld Fittings covers all sizes, grade and constrcution from 1/2" to 96" Fittings Type: Elbow, Reducer Concentric, Reducer Eccentric, Tee Reducing, Tee Equal, Weldolet, Sockolet, Nipolet, Hex Nipple, Bend, Barred Tee, Coupling, Cap, Bushing Etc.,</p>
            <h1 style={ styles.h1 }>Fittings Range</h1>
            <table style={styles.table}>
                <thead style={ styles.tableHeader}>
                    <tr style={ styles.tableRow }>
                        <th width="20%" style={ styles.heading }>Material</th>
                        <th width="20%" style={ styles.heading }>Constructions</th>
                        <th width="20%" style={ styles.heading }>Norms</th>
                        <th width="20%" style={ styles.heading }>Grade</th>
                        <th width="20%" style={ styles.heading }>Range</th>
                    </tr>
                </thead>
                <tbody style={ styles.body }>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Carbon Steel</td>
                        <td style={ styles.cell }>Seamless/Welded</td>
                        <td style={ styles.cell }>A234 A860 MSS SP75 A420</td>
                        <td style={ styles.cell }>WPB WPHY42, WPHY52, WPHY60, WPHY65 WPHY42, WPHY52, WPHY60, WPHY65 WPL6</td>
                        <td style={ styles.cell }>1/2”- 96”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Carbon Steel</td>
                        <td style={ styles.cell }>Forged</td>
                        <td style={ styles.cell }>A105N A350 A694</td>
                        <td style={ styles.cell }>LF2 F42, F52, F60, F65</td>
                        <td style={ styles.cell }>1/4”- 4” 1/2”- 2”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Alloy Steel</td>
                        <td style={ styles.cell }>Seamless/Welded/Forged</td>
                        <td style={ styles.cell }>A234 A182</td>
                        <td style={ styles.cell }>WP11, WP22, WP5, WP91 F1, F5, F11, F12, F22, F91, F92</td>
                        <td style={ styles.cell }>1/2”- 96” 1/4”- 4”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Stainless Steel</td>
                        <td style={ styles.cell }>Seamless/Welded/Forged</td>
                        <td style={ styles.cell }>A403 A815 A182</td>
                        <td style={ styles.cell }>WP304/304L, WP316/316L UNS S31803, UNS S32750 F304/304L, N06625 F316/316L F50, F51, F52, F53, F54, F55 F57, F60, F61</td>
                        <td style={ styles.cell }>1/2”- 48” 1/2”- 16” 1/4”- 4”</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const styles = {
    container: {
        padding: '4rem',
        background: '#FFFFFF',
        boxShadow: '0 5px 35px rgba(0, 0, 0, 0.15)',
        borderRadius: '5px'
    },
    description: {
        fontSize: '1.3rem',
        lineheight: '1.6rem',
        color: '#444444'
    },
    h1: {
        paddingBottom: '10px',
        color: '#444444'
    },
    table: {
        width: '100%',
        textAlign: 'center',
    },
    tableHeader: {
        backgroundColor: '#444444',
        color: 'white',
    },
    tableRow: {

    },
    heading: {
        padding: '20px',
        textTransform: 'uppercase'
    },
    body: {
        backgroundColor: 'aliceblue'
    },
    bodyRow: {

    },
    col1: {
        padding: '20px',
        backgroundColor: '#488caa',
        color: '#FFFFFF',
        borderBottom: '1px solid white',
        fontWeight: 700,
    },
    cell: {
        padding: '20px',
        borderBottom: '1px solid gray',
    }
}
