import React from 'react';

export default function ValvesComponent() {
    return (
        <div style={styles.container}>
            <p style={ styles.description }>MZ Engineering supplies valves which includes a comprehensive range of gate, globe, check, ball, and butterfly valves. in all varieties of carbon steel, stainless steel, duplex, and other special alloys.</p>
            <h1 style={ styles.h1 }>Valves Range</h1>
            <table style={styles.table}>
                <thead style={ styles.tableHeader}>
                    <tr style={ styles.tableRow }>
                        <th width="20%" style={ styles.heading }>Material</th>
                        <th width="20%" style={ styles.heading }>Size Range</th>
                        <th width="20%" style={ styles.heading }>Class Range</th>
                        <th width="20%" style={ styles.heading }>End type range</th>
                        <th width="20%" style={ styles.heading }>Standard/Specs</th>
                    </tr>
                </thead>
                <tbody style={ styles.body }>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Carbon Steel Forged</td>
                        <td style={ styles.cell }>Less than 2"</td>
                        <td style={ styles.cell }>150-2500</td>
                        <td style={ styles.cell }>Flanged End</td>
                        <td style={ styles.cell }>API 6A</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>carbon Steel Casting</td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }>API 6D</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Stainless Steel</td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }>API 6DSS</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Super Duplex Stainless Steel</td>
                        <td style={ styles.cell }>Larger than 2"</td>
                        <td style={ styles.cell }>3K PSI - 20K PSI</td>
                        <td style={ styles.cell }>Welded End, Threaded End</td>
                        <td style={ styles.cell }>API 600</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Ductile Iron</td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }>API 602</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Bonze</td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }></td>
                        <td style={ styles.cell }>TAT UL/FM</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const styles = {
    container: {
        padding: '4rem',
        background: '#FFFFFF',
        boxShadow: '0 5px 35px rgba(0, 0, 0, 0.15)',
        borderRadius: '5px'
    },
    description: {
        fontSize: '1.3rem',
        lineheight: '1.6rem',
        color: '#444444'
    },
    h1: {
        paddingBottom: '10px',
        color: '#444444'
    },
    table: {
        width: '100%',
        textAlign: 'center',
    },
    tableHeader: {
        backgroundColor: '#444444',
        color: 'white',
    },
    tableRow: {

    },
    heading: {
        padding: '20px',
        textTransform: 'uppercase'
    },
    body: {
        backgroundColor: 'aliceblue'
    },
    bodyRow: {

    },
    col1: {
        padding: '20px',
        backgroundColor: '#488caa',
        color: '#FFFFFF',
        borderBottom: '1px solid white',
        fontWeight: 700,
    },
    cell: {
        padding: '20px',
        borderBottom: '1px solid gray',
    }
}