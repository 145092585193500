import React from 'react';

export default function TextAreaComponent({label, placeholder}){
    return (
        <div className="input-box">
            <span>{label}</span>
            <textarea placeholder={placeholder}></textarea>
        </div>
    )
}
