import './style/style.css';
import React from "react";
import Typical from "react-typical";

import ImageSlider from './components/ImageSlider';

export default function Home(){
    return (
        <div className="home">
            <div className="image-container">
                <ImageSlider />
                <div class="topleft">
                    <h1>Maqbool Zadjali Engineering Services llc</h1>
                    <h2>
                        {""}
                        <Typical
                            loop={Infinity}
                            steps={[
                                "Reliable Partner",
                                4000,
                                "Quality at its best",
                                4000
                            ]}
                        />
                    </h2>
                </div>
            </div>
            <div className="container">
                <div className="inner__container">
                    <div className="company__profile">
                        <h2>Company Profile</h2>
                        <div className="dash"></div>
                        <p>
                        MZ Engineering LLC, is a leading pipe, fittings, flanges, valves & fasteners supplier in the middle east, who has been providing pipe and fittings to a wide range of industries since its establishment in 2014. Our pipes, fittings, flanges, valves and gaskets provide a complete package for our clients of diverse industrial backgrounds.
            MZ Engineering LLC established in 2014, and since then we have gradually worked our way to become a competent supplier of pipes, fittings, flanges & Valves in the middle east. More than four decades experience of our founders has led us to constantly bring customer satisfaction to the next level, in terms of the product quality and our services.
            The founders and directors are instrumental in dominating the overall direction of the company, lending their best of experience and know-how of the industry into MZ Engineering LLC.
            MZ Engineering LLC, develops loyal relationships through our business values, commitment to serve & industry expertise, creating lifelong partnerships.
            Our desire to do better is never done.  We continue to look for ways to improve how we serve our partners, run our operations and support our people.

            We look forward to working with you!
                        </p>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card__container">
                                <div className="card">
                                    <h3>What We Do</h3>
                                    <p>
                                    Committed and stay focused in whatever we do, our company has never stop finding ways in meeting your demands and expectations, pledged to offer the finest quality of hardware products and viable fitting solutions at reasonable price. We strive to differentiate from our rivalries in Middle East.  Our large selection of pipe and fitting hardware products such as valves, gaskets and flanges are able to cater to diversified industries such as Oil and Gas Industry; you name it, we have it. We do have different categories available here like carbon steel fittings, carbon steel pipes, etcetera.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col">
                            <div className="card__container">
                                <div className="company__profile">
                                    <h3>Commitment & Strength</h3>
                                    <p>
                                    Quality Products – the years of involvement in the industry, with the valuable experience from founders and directors has absolutely assured of this conviction to our valued customers. Suppliers and vendors have been with us since day one, so that we are able to ensure products sourced and delivered are of optimum quality, further screened by our procurement department prior to delivering to customers.  Unique Pricing – Enjoying the fruits of long established relationship with suppliers and vendors has enabled Chuan Foong Hardware to be in the front-runner to secure best pricing in the stocks we source, directly translating into customers’ benefits in terms of pricing and savings. This unique presence in MZ ENGINEERING SERVICES allows ample rooms for negotiation between us and our valued customers
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
