import React from "react";

export default function DevelopComponent() {
    return (
        <div className="block">
            <div className="inner-container">
                <h1 className="title">Develop</h1>
                <p className="paragraph">We will develop a sense of pride, ownership and responsiveness across all levels of our company. This will reflect in our dealings with clients, suppliers and fellow employees.</p>
            </div>
        </div>
    )
}