import React from 'react';

export default function PipesComponent() {
    return (
        <div style={styles.container}>
            <p style={ styles.description }>MZ Engineering is a supplier of a wide range of seamless and welded pipes in all grades of carbon steel, alloy steel, stainless steel, duplex, super duplex and higher alloys in sizes from 1/8’’ to 120” NPS.</p>
            <h1 style={ styles.h1 }>Pipes Range</h1>
            <table style={styles.table}>
                <thead style={ styles.tableHeader}>
                    <tr style={ styles.tableRow }>
                        <th width="20%" style={ styles.heading }>Material</th>
                        <th width="20%" style={ styles.heading }>Constructions</th>
                        <th width="20%" style={ styles.heading }>Norms</th>
                        <th width="20%" style={ styles.heading }>Grade</th>
                        <th width="20%" style={ styles.heading }>Range</th>
                    </tr>
                </thead>
                <tbody style={ styles.body }>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Carbon Steel</td>
                        <td style={ styles.cell }>Seamless</td>
                        <td style={ styles.cell }>API 5L A106/A53 A106 A333</td>
                        <td style={ styles.cell }>B, X42, X52, X60, X65, X70, X80 B GrA, GrC Gr3, Gr6</td>
                        <td style={ styles.cell }>1/4”- 24”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Carbon Steel</td>
                        <td style={ styles.cell }>Welded</td>
                        <td style={ styles.cell }>API5L A53 A671 A672</td>
                        <td style={ styles.cell }>B, X42, X52, X60, X65, X70,X80 B CC60, CC65 CC60, CC65</td>
                        <td style={ styles.cell }>2”-120”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Alloy Steel</td>
                        <td style={ styles.cell }>Seamless</td>
                        <td style={ styles.cell }>A335</td>
                        <td style={ styles.cell }>P1, P11, P12, P22, P5, P9, P91, P92</td>
                        <td style={ styles.cell }>1/2”- 24”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Alloy Steel</td>
                        <td style={ styles.cell }>Welded</td>
                        <td style={ styles.cell }>A691</td>
                        <td style={ styles.cell }>1CR, 1 1/4CR, 2 1/4CR, 5CR, 9CR, 91</td>
                        <td style={ styles.cell }>2”-120”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Stainless Steel & Higher Grades</td>
                        <td style={ styles.cell }>Seamless</td>
                        <td style={ styles.cell }>A312 A790 B444</td>
                        <td style={ styles.cell }>TP304/304L, TP316/316L TP321, TP321H, TP347, TP347H UNS S31803, UNS S32750, N06625</td>
                        <td style={ styles.cell }>3/8”- 24”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Stainless Steel & Higher Grades</td>
                        <td style={ styles.cell }>Welded</td>
                        <td style={ styles.cell }>A312/A358 A312 A790 B474</td>
                        <td style={ styles.cell }>TP304/304L, TP316/316L TP321, TP321H, TP347, TP347H UNS S31803, UNS S32750, N06625</td>
                        <td style={ styles.cell }>2”- 80”</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const styles = {
    container: {
        padding: '4rem',
        background: '#FFFFFF',
        boxShadow: '0 5px 35px rgba(0, 0, 0, 0.15)',
        borderRadius: '5px'
    },
    description: {
        fontSize: '1.3rem',
        lineheight: '1.6rem',
        color: '#444444'
    },
    h1: {
        paddingBottom: '10px',
        color: '#444444'
    },
    table: {
        width: '100%',
        textAlign: 'center',
    },
    tableHeader: {
        backgroundColor: '#444444',
        color: 'white',
    },
    tableRow: {

    },
    heading: {
        padding: '20px',
        textTransform: 'uppercase'
    },
    body: {
        backgroundColor: 'aliceblue'
    },
    bodyRow: {

    },
    col1: {
        padding: '20px',
        backgroundColor: '#488caa',
        color: '#FFFFFF',
        borderBottom: '1px solid white',
        fontWeight: 700,
    },
    cell: {
        padding: '20px',
        borderBottom: '1px solid gray',
    }
}