import React from 'react';

export default function FlangesComponent() {
    return (
        <div style={styles.container}>
            <p style={ styles.description }>MZ Engineering is a supplier of a wide range of ASME Flanges & API Flanges in class from 150 to 2500, sizes staring from 1/4" to 72" Flanges Type: Weldneck Flange, Flange sockolet, Slip on Flange, Spectacle Blind Flange, Orifice Flange, Lap Joint Flange, Nipo Flange, Spade, Spacer Etc.,</p>
            <h1 style={ styles.h1 }>Flanges Range</h1>
            <table style={styles.table}>
                <thead style={ styles.tableHeader}>
                    <tr style={ styles.tableRow }>
                        <th width="25%" style={ styles.heading }>Material</th>
                        <th width="25%" style={ styles.heading }>Norms</th>
                        <th width="25%" style={ styles.heading }>Grade</th>
                        <th width="25%" style={ styles.heading }>Range</th>
                    </tr>
                </thead>
                <tbody style={ styles.body }>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Carbon Steel</td>
                        <td style={ styles.cell }>A105N A350 A694</td>
                        <td style={ styles.cell }>LF2 F42, F52, F60, F65</td>
                        <td style={ styles.cell }>1/4”- 72”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Carbon Steel</td>
                        <td style={ styles.cell }>A707</td>
                        <td style={ styles.cell }>L3 CL3, L5 CL3</td>
                        <td style={ styles.cell }>12”- 48”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Alloy Steel</td>
                        <td style={ styles.cell }>A182</td>
                        <td style={ styles.cell }>F1, F11, F12, F22, F5, F91, F92</td>
                        <td style={ styles.cell }>1/2”- 48”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Stainless Steel & Higher Grades</td>
                        <td style={ styles.cell }>A182 B564</td>
                        <td style={ styles.cell }>F304/304L, F316/316L F50, F51, F52, F53, F54, F55, F57, F60, F61, N06625</td>
                        <td style={ styles.cell }>1/2”- 60” 1/2”- 24”</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const styles = {
    container: {
        padding: '4rem',
        background: '#FFFFFF',
        boxShadow: '0 5px 35px rgba(0, 0, 0, 0.15)',
        borderRadius: '5px'
    },
    description: {
        fontSize: '1.3rem',
        lineheight: '1.6rem',
        color: '#444444'
    },
    h1: {
        paddingBottom: '10px',
        color: '#444444'
    },
    table: {
        width: '100%',
        textAlign: 'center',
    },
    tableHeader: {
        backgroundColor: '#444444',
        color: 'white',
    },
    tableRow: {

    },
    heading: {
        padding: '20px',
        textTransform: 'uppercase'
    },
    body: {
        backgroundColor: 'aliceblue'
    },
    bodyRow: {

    },
    col1: {
        padding: '20px',
        backgroundColor: '#488caa',
        color: '#FFFFFF',
        borderBottom: '1px solid white',
        fontWeight: 700,
    },
    cell: {
        padding: '20px',
        borderBottom: '1px solid gray',
    }
}
