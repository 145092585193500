import './styles.css';
import React from "react";

import FormComponent from './components/FormComponent';
import ContactInfoComponent from './components/ContactInfoComponent';

export default function Contact(){
    return (
        <div className="contact-us">
            <div className="contact-us__container">
                <div className="title__container">
                    <h2 className="title">
                        Contact Us
                    </h2>
                </div>
                <div className="box">
                    <div className="contact form">
                        <h5>Send a Message</h5>
                        <FormComponent />
                    </div>
                    <div className="contact info">
                        <h5>Contact Info</h5>
                        <ContactInfoComponent />
                    </div>
                    <div className="contact map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.20985584298!2d58.4318445150637!3d23.59680560054323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91ff114fb8245f%3A0x5194e72f1aa006c9!2sAl%20Khuwair%20St%2C%20Muscat%2C%20Oman!5e0!3m2!1sen!2sin!4v1675599393260!5m2!1sen!2sin" loading="lazy" title="map" ></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
