import React from 'react';
import { Outlet } from "react-router-dom";

// import {
//     Container,
//     Nav,
//     Navbar
// } from 'react-bootstrap';
// import {
//   useLocation
// } from "react-router-dom";

import FooterComponent from './components/Footer';
import HeaderComponent from './components/Header';

// const MainLayout = () => {
//     let location = useLocation();
//     let pathname = [];
//     pathname = location.pathname.split('/');
//     return (
//         <Navbar  bg="dark" expand="lg" variant="dark">
//             <Container>
//                 <Navbar.Brand >Home</Navbar.Brand>
//                 <Navbar.Toggle aria-controls="basic-navbar-nav" />
//                 <Navbar.Collapse id="basic-navbar-nav">
//                     <Nav className="me-auto" activeKey={pathname[1]}>
//                         <Nav.Link eventKey="" href="/">Home</Nav.Link>
//                         <Nav.Link eventKey="about" href="/about">About</Nav.Link>
//                         <Nav.Link eventKey="products" href="/products">Products</Nav.Link>
//                         <Nav.Link eventKey="career" href="/career">Career</Nav.Link>
//                         <Nav.Link eventKey="contact" href="/contact">Contact</Nav.Link>
//                     </Nav>
//                 </Navbar.Collapse>
//             </Container>
//         </Navbar>
//     )
// };

export default function Layout() {
    return (
        <div>
            <HeaderComponent />
            <Outlet />
            <FooterComponent />
        </div>
    )
}
