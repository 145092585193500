import '../style/header.css';
import React from 'react';
import {
  useLocation
} from "react-router-dom";

export default function Header(){
    const [show, setShow] = React.useState(false);

    let location = useLocation();
    let pathname = [];
    pathname = location.pathname.split('/');

    const checkActive = (key) => {
        if (pathname[1] === key) {
            return 'active';
        } else {
            return 'inactive';
        }
    }

    const toggleMenu = () => {
        setShow(!show);
        document.body.classList.toggle('fixed-position');
    }
    // return (
    //     <div className="header">
    //         <div className="header__container">
    //             <div className="header__row">
    //                 <div className="header__brand_container">
    //                     <img className="header__logo" src={require('../../assets/images/mzeslogo.png')}/>
    //                 </div>
    //                 <div className="header__menu_container">
    //                     <ul>
    //                         <li className={checkActive("")}><a href="/">Home</a></li>
    //                         <li className={checkActive("about")}><a href="/about">About</a></li>
    //                         <li className={checkActive("products")}><a href="/products">Products</a></li>
    //                         <li className={checkActive("career")}><a href="career">Career</a></li>
    //                         <li className={checkActive("contact")}><a href="contact">Contact</a></li>
    //                     </ul>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )

    return (
        <nav>
            <div className="logo__container">
                <img src={require('../../assets/images/mzeslogo.png')} alt="logo"/>
            </div>
            <label id="menu-icon" onClick={toggleMenu}>
                <i className="fas fa-bars"></i>
            </label>
            <ul className={show?'show': ''}>
                <li><a className={checkActive("")} href="/">Home</a></li>
                <li><a  className={checkActive("about")} href="/about">About</a></li>
                <li><a className={checkActive("products")} href="/products">Products</a></li>
                <li><a className={checkActive("contact")} href="contact">Contact</a></li>
            </ul>
        </nav>
    )
}
