import React from "react";

export default function InnovationComponent() {
    return (
        <div className="block">
            <div className="inner-container">
                <h1 className="title">Innovation</h1>
                <p className="paragraph">We will value innovation highly. Our passion for it will enable us to be at the sharp end of modern management methods and technological advances to ensure that we have the best resources and know how for our clients & suppliers base across the globe.</p>
            </div>
        </div>
    )
}