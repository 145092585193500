import React from "react";

export default function OurGoalComponent() {
    return (
        <div className="block">
            <div className="inner-container">
                <h1 title="title">Our Goal</h1>
                <p className="paragraph">Reduce costs and eliminate disruptions during procurement, supply chain, and operational processes.</p>
            </div>
        </div>
    )
}