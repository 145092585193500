import React from 'react';

export default function BoltsComponent() {
    return (
        <div style={styles.container}>
            <p style={ styles.description }>MZ Engineering supplies stud bolts for high temperature and high tensile applications in carbon steel, alloy, and stainless steel. Higher alloys can also be supplied to client requirements.</p>
            <h1 style={ styles.h1 }>Stud Bolts Range</h1>
            <table style={styles.table}>
                <thead style={ styles.tableHeader}>
                    <tr style={ styles.tableRow }>
                        <th width="20%" style={ styles.heading }>Material</th>
                        <th width="20%" style={ styles.heading }>Norms</th>
                        <th width="20%" style={ styles.heading }>Grade</th>
                        <th width="20%" style={ styles.heading }>Range</th>
                    </tr>
                </thead>
                <tbody style={ styles.body }>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Stud Bolts</td>
                        <td style={ styles.cell }>A193 A320</td>
                        <td style={ styles.cell }>B7, B7M, B8, B8M L7, L7M</td>
                        <td style={ styles.cell }>1/4”- 3” 1/4”- 2”</td>
                    </tr>
                    <tr style={ styles.bodyRow }>
                        <td style={ styles.col1 }>Nuts</td>
                        <td style={ styles.cell }>A194</td>
                        <td style={ styles.cell }>2H, 2HM, Gr4, Gr8, Gr8M</td>
                        <td style={ styles.cell }>5/8”- 3”</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const styles = {
    container: {
        padding: '4rem',
        background: '#FFFFFF',
        boxShadow: '0 5px 35px rgba(0, 0, 0, 0.15)',
        borderRadius: '5px'
    },
    description: {
        fontSize: '1.3rem',
        lineheight: '1.6rem',
        color: '#444444'
    },
    h1: {
        paddingBottom: '10px',
        color: '#444444'
    },
    table: {
        width: '100%',
        textAlign: 'center',
    },
    tableHeader: {
        backgroundColor: '#444444',
        color: 'white',
    },
    tableRow: {

    },
    heading: {
        padding: '20px',
        textTransform: 'uppercase'
    },
    body: {
        backgroundColor: 'aliceblue'
    },
    bodyRow: {

    },
    col1: {
        padding: '20px',
        backgroundColor: '#488caa',
        color: '#FFFFFF',
        borderBottom: '1px solid white',
        fontWeight: 700,
    },
    cell: {
        padding: '20px',
        borderBottom: '1px solid gray',
    }
}
