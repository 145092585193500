import React from 'react';

export default function TextInputComponent({label, type, placeholder}) {
    return (
        <div className="input-box">
            <span>{label}</span>
            <input type={type} placeholder={placeholder}/>
        </div>
    )
}
