import React from 'react';

export default function BannerComponent({title, imageUrl}) {
    return (
        <div style={styles.container}>
            <div style={styles.titleContainer}>
                <h1>{title}</h1>
            </div>           
            <img style={styles.image} src={imageUrl} alt="ermz"/>
        </div>
    )
}

const styles = {
    container: {
        position: 'relative'
    },
    image: {
        width: '100vw'
    },
    titleContainer: {
        position: 'absolute',
        bottom: 0,
        marginBottom: '20px',
        marginLeft: '50px',
        padding: '10px 40px',
        backgroundColor: 'gray',
        borderRadius: '5px',
        color: 'white',

    },
    title: {
    }
}