import React from "react";


const ButtonComponent = ({ href, title, activeTab, onClickTab }) => {
    const [active, setActive] = React.useState(activeTab === title ? true : false);
    React.useEffect(() => {
        activeTab === title ? setActive(true) : setActive(false)
    },[activeTab, title]);

    return (
        <li
            onClick={() => onClickTab(title)}
            style={ active ? {...styles.button, ...styles.activeButton } : styles.button}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => { activeTab === title ? setActive(true) : setActive(false) }}
        >
            <span style={ styles.link }>{title}</span>
        </li>
    )
}

export default function TopbarComponent({activeTab, onClickTab}) {
    return (
        <div style={ styles.container }>
            <ul style={ styles.row }>
                <ButtonComponent href="#pipes" title="Pipes" activeTab={activeTab} onClickTab={onClickTab} />
                <ButtonComponent href="#fittings" activeTab={activeTab} title="Fittings" onClickTab={onClickTab}/>
                <ButtonComponent href="#flanges" activeTab={activeTab} title="Flanges" onClickTab={onClickTab}/>
                <ButtonComponent href="#gaskets" activeTab={activeTab} title="Gaskets" onClickTab={onClickTab}/>
                <ButtonComponent href="#stud-bolts" activeTab={activeTab} title="Stud Bolts" onClickTab={onClickTab}/>
                <ButtonComponent href="#valves" activeTab={activeTab} title="Valves" onClickTab={onClickTab}/>
            </ul>
        </div>
    )
}

const styles = {
    container: {
        padding: '1px 3rem',
        margin: '0px'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 500,
        padding: 0,
        margin: 0
    },
    button: {
        display: 'inline-block',
        width: '16.5%',
        padding: '20px',
        border: '1px solid black',
        borderColor: '#488caa',
        color: '#488caa'
    },
    activeButton: {
        backgroundColor: '#488caa',
        color: '#FFF'
    },
    link: {
        fontSize: '20px',
        textDecoration: 'none',
        width: '100%'
    }
}