import React from "react";

export default function ClientRelationshipComponent() {
    return (
        <div className="block">
            <div className="inner-container">
                <h1 className="title">Client Relationship</h1>
                <p className="paragraph">We will place our customers at the centre of all our actions. We will remain focused on building client relationships and loyalty through culture of service orientation. We will make available and deliver by all visible channels, a comprehensive range of services to our esteemed client base.</p>
            </div>
        </div>
    )
}