import React from "react";

import Topbar from './components/TopbarComponent';
import BoltsComponent from './components/BoltsComponent';
import FittingsComponent from './components/FittingsComponent';
import FlangesComponent from './components/FlangesComponent';
import PipesComponent from './components/PipesComponent';
import ValvesComponent from './components/ValvesComponent';
import GasketsComponent from "./components/GasketsComponent";
import BannerComponent from "./components/BannerComponent";

export default function Products(){
    const [activeTab, setActiveTab] = React.useState("Pipes");

    const showContent = () => {
        switch (activeTab) {
            case 'Pipes':
                return <PipesComponent />
            case 'Fittings':
                return <FittingsComponent />            
            case 'Flanges':
                return <FlangesComponent />                    
            case 'Gaskets':
                return <GasketsComponent />            
            case 'Stud Bolts':
                return <BoltsComponent />            
            case 'Valves':
                return <ValvesComponent />                                                                                
            default:
                break;
        }
    }

    const marginLeft = () => {
        switch (activeTab) {
            case 'Pipes':
                return '0%';
            case 'Fittings':
                return '-100%'           
            case 'Flanges':
                return '-200%'                   
            case 'Gaskets':
                return '-300%'            
            case 'Stud Bolts':
                return '-400%'            
            case 'Valves':
                return '-500%'                                                                               
            default:
                break;
        }
    }

    return (
        <div>
            <div style={ styles.section }>
                <div style={{ ...styles.row, marginLeft: marginLeft() }}>
                    <BannerComponent title="Pipes" imageUrl={require('../../assets/images/pipes-banner2.jpeg')} />
                    <BannerComponent title="Fittings" imageUrl={require('../../assets/images/fittings-banner.jpeg')} />
                    <BannerComponent title="Flanges" imageUrl={require('../../assets/images/flanges-banner.jpeg')} />
                    <BannerComponent title="Gaskets" imageUrl={require('../../assets/images/gaskets-banner.jpeg')} />
                    <BannerComponent title="Stud Bolts" imageUrl={require('../../assets/images/stud-bolts-banner.jpeg')} />
                    <BannerComponent title="Valves" imageUrl={require('../../assets/images/valves-banner.jpeg')} />
                </div>
            </div>
            <Topbar activeTab={activeTab} onClickTab={(t) => setActiveTab(t)}/>
            <div style={ styles.content }>
                { showContent() }
            </div>
        </div>
    )
}

const styles = {
    section: {
        overflow: 'hidden',
        width: '100vw'
    },
    row: {
        display: 'flex',
        width: '600%',
        transition: '2s'
    },
    content: {
        backgroundColor: '#488caa',
        padding: '4rem 5rem'
    }
}
