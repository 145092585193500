import React from 'react';

export default function ContactInfoComponent() {
    return (
        <div className="info__box">
            <div>
                <span><ion-icon name="location-outline"></ion-icon></span>
                <p>PO: 799, South Al Khuwair, Bousher, Postcode: 116<br />Muscat - Sultanate of Oman</p>
            </div>
            <div>
                <span><ion-icon name="mail-outline"></ion-icon></span>
                <a href="mailto:support@mzesllc.com">support@mzesllc.com</a>
            </div>
            <div>
                <span><ion-icon name="call-outline"></ion-icon></span>
                <a href="tel:+968979042016">+968 97904 2016</a>
            </div>
            <div className="last">
                <span><ion-icon name="call-outline"></ion-icon></span>
                <a href="tel:+96892699426">+968 9269 9426</a>
            </div>
        </div>
    )
}
