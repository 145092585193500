import './styles/styles.css';
import React from "react";
import ClientRelationshipComponent from "./components/ClientRelationshipComponent";
import DevelopComponent from "./components/DevelopComponent";
import InnovationComponent from "./components/InnovationComponent";
import OurGoalComponent from "./components/OurGoalComponent";
import OurMissionComponent from "./components/OurMissionComponent";
import QualityComponent from "./components/QualityComponent";

export default function Career(){
    return (
        <div className="quality-container">
            <div className="quality-inner-container">
                <QualityComponent />
                <OurGoalComponent />
                <OurMissionComponent />
                <ClientRelationshipComponent />
                <InnovationComponent />
                <DevelopComponent />
            </div>
        </div>
    )
}
