import React from 'react';
import { Route, Routes } from "react-router-dom";

import {
    About,
    Career,
    Contact,
    Home,
    Products
} from '../pages';

import {
    MainLayout
} from '../layouts';

export default function App() {
    return (
        <Routes>
            <Route exact path="/" element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route exact path="about" element={<About />} />
                <Route exact path="products" element={<Products />} />
                <Route exact path="career" element={<Career />} />
                <Route exact path="contact" element={<Contact />} />
            </Route>
            <Route
                path="*"
                element={
                    <main>
                        <p>404 NOT FOUND</p>
                    </main>
                }
            />
        </Routes>
    )
}
