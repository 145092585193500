import React from "react";

export default function QualityComponent() {
    return (
        <div className="block">
            <div className="inner-container">
                <h1 className="title">Quality</h1>
                    <p className="paragraph">
                    In an increasingly competitive market, where standards are getting stricter and practices are evolving rapidly, quality management is of paramount importance to corporate success and project optimization. At MZ Engineering, ensuring deliverable excellence and full client satisfaction is the cornerstone of our operations. Every detail counts, and the entire team has a role to play in achieving these results.

        Our technical experts deliver Total Quality Assurance across the entire oil and gas supply chain - from testing, inspection and certification to consulting, technical, engineering and related services.

                    </p>
            </div>
        </div>
    )
}