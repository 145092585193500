import React from 'react';

import TextInputComponent from './TextInputComponent';
import TextAreaComponent from './TextAreaComponent';
import ButtonComponent from './ButtonComponent';

export default function FormComponent() {
    return (
        <form>
            <div className="form-box">
                <div className="row-50">
                    <TextInputComponent label="First Name" type="text" placeholder="John"/>
                    <TextInputComponent label="Last Name" type="text" placeholder="Doe"/>
                </div>
                <div className="row-50">
                    <TextInputComponent label="Email" type="email" placeholder="johndoe@email.com"/>
                    <TextInputComponent label="Mobile" type="number" placeholder="+968********"/>
                </div>
                <div className="row-100">
                    <TextAreaComponent label="Message" placeholder="Write your message here..."/>
                </div>
                <div className="row-100">
                    <ButtonComponent type="submit" title="Send" />
                </div>
            </div>
        </form>
    )
}
