import React from "react";
import './style/about.css';

export default function About(){
    return (
        <div className="about__us">
            <div className="about__us__container">
                <div>
                    <h3>About us</h3>
                    <p>
                    MZ Engineering LLC, is a company that distributing and supplying an extensive range of high-quality pipe fittings, flanges, valves, and Fasteners. We are aiming for built-on quality, reliability, and expertise tailored to suit your needs.  In MZ Engineering LLC,, Our business policy is to provide our products to our customers with the best quality. With our professional and experienced staff, we have the ability to satisfy customers' requirements. We are aiming the long-term business relationship with our clients. So we believe in gaining customer's trust with high-quality products is the only way to satisfying and retaining customers.  Our continuous growth is a result of our dependable service, reliable inventory and commitment to coming through. When other suppliers can’t deliver, MZ Engineering LLC, rises to the challenge without hesitation, providing everything you need to move your projects forward.
                    Through a commitment to quality and constant drive to improve, MZ Engineering exceeds expectations at every turn – because excellence is a continuous process.

                    MZ Engineering LLC, prides ourselves on providing quality products and excellent service to our customers, resulting in relationships that will last generations.

                    If you are interested in any of our products or services, feel free to contact us for more information. We will delight to hear from you.
                    </p>
                </div>
                <div className="line1"/>
                <div>
                    <h3>Our directors</h3>
                    <div className="card2Container">
                        <div className="card2">
                            <div className="card__row">
                                <div className="image__container">
                                    <img src={require('../../assets/images/ermz.jpeg')} alt="ermz"/>
                                </div>
                                <div className="content__container">
                                    <h4>Engr. Maqbool Hussein Salih Al Zadjali</h4>
                                    <p>
                                    Engr. Maqbool Hussein Salih Al Zadjali has over 35 years of experience in the Oil and Gas sectors, with particular expertise in major pipeline projects, construction management as well facilities projects. 
                        He holds bachelor degree in Mechanical Engineering from university of Sunderland (UK). 
                        Maqbool spent most of his career in PDO, his last position was Engineering Manager (North) before proceeding on early retirement, prior to which he also worked in Singapore as resident engineer for PDO projects and then was assigned to Malaysia (Sarawak) for 4.5 years managing offshore pipeline projects for Shell Malaysia.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div />
            </div>
        </div>
    )
}
