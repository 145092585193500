import '../style/footer.css';
import React from 'react';

export default function FooterComponent() {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__row">
                    <div className="footer__col">
                        <div className="footer__logo__container">
                            <img className="footer__logo" src={require('../../assets/images/mzeslogo.png')} alt="logo"/>
                            <h5>Reliable partner for pipping needs</h5>
                        </div>
                    </div>
                    <div className="footer__col">
                        <h4>Products</h4>
                        <ul>
                            <li><a href="/products?#pipes">Pipes</a></li>
                            <li><a href="/products?#fittings">Fittings</a></li>
                            <li><a href="/products?#flanges">Flanges</a></li>
                            <li><a href="/products?#gaskets">Gaskets</a></li>
                            <li><a href="/products?#stud-bolts">Stud Bolts</a></li>
                            <li><a href="/products?#valves">Valves</a></li>
                        </ul>
                    </div>
                    <div className="footer__col">
                        <h4>Company</h4>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/products">Products</a></li>
                            <li><a href="/career">Career</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}
