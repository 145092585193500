import React from "react";

export default function OurMissionComponent() {
    return (
        <div className="block">
            <div className="inner-container">
                <h1 className="title">Our Mission</h1>
                <p className="paragraph">Connect quality products to oil & gas industry.</p>
            </div>
        </div>
    )
}