import React from "react";
// JSX
// import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";
// import Wrapper from "../UI/Wrapper/Wrapper";
// import Title from "../UI/Title/Title";
// import Subtitle from "../UI/Subtitle/Subtitle";
//
import image1 from "../../../assets/images/wallpaper1.jpeg"
import image2 from "../../../assets/images/wallpaper2.jpeg"
import image3 from "../../../assets/images/wallpaper3.jpeg"
import image4 from "../../../assets/images/wallpaper4.jpeg"

// export default function ImageSlider() {
//     const [selectedImage, setSelectedImage] = React.useState(0)
//     const [allImages, setAllImages] = React.useState([image1, image2, image3, image4])
//
//     React.useEffect(() => {
//       setInterval(() => {
//         setSelectedImage(selectedImage => selectedImage < 3 ? selectedImage + 1 : 0)
//     }, 5000)
//     }, [])
//     return (
//         <div>
//             <img  src={allImages[selectedImage]} /> <br />
//         </div>
//     )
// }

export default function ImageSlider() {
    const [counter, setCounter] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCounter(counter => counter + 1);
        }, 4000);

        return () => {
            clearInterval(interval);
        };
    }, []);

     return (
        <div className="section">
            <div className="slider">
                <div className="slide">
                    <input type="radio" checked={counter%4 === 0} name="radio-btn" id="radio1" />
                    <input type="radio" checked={counter%4 === 1} name="radio-btn" id="radio2" />
                    <input type="radio" checked={counter%4 === 2} name="radio-btn" id="radio3" />
                    <input type="radio" checked={counter%4 === 3} name="radio-btn" id="radio4" />
                    <div className="st first">
                        <img src={image1} alt="image1"/>
                    </div>
                    <div className="st">
                        <img src={image2} alt="image2"/>
                    </div>
                    <div className="st">
                        <img src={image3} alt="image3"/>
                    </div>
                    <div className="st">
                        <img src={image4} alt="image4"/>
                    </div>
                    <div className="nav-auto">
                        <div className="a-b1"></div>
                        <div className="a-b2"></div>
                        <div className="a-b3"></div>
                        <div className="a-b4"></div>
                    </div>
                    <div className="nav m">
                        <label for="radio1" className="m-btn"></label>
                        <label for="radio1" className="m-btn"></label>
                        <label for="radio1" className="m-btn"></label>
                        <label for="radio1" className="m-btn"></label>
                    </div>
                </div>
            </div>
        </div>
    )
}
